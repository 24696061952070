.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.66667%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
     -moz-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.33333%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.66667%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 41.66667%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 58.33333%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.66667%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 83.33333%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 91.66667%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
     -moz-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
  -webkit-order: 13;
     -moz-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
     -moz-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
     -moz-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
     -moz-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
     -moz-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
     -moz-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
  -webkit-order: 5;
     -moz-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
  -webkit-order: 6;
     -moz-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
  -webkit-order: 7;
     -moz-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
  -webkit-order: 8;
     -moz-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
  -webkit-order: 9;
     -moz-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
  -webkit-order: 10;
     -moz-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
  -webkit-order: 11;
     -moz-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
  -webkit-order: 12;
     -moz-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    -webkit-border-top-left-radius: 0.25rem;
       -moz-border-radius-topleft: 0.25rem;
            border-top-left-radius: 0.25rem;
    -webkit-border-top-right-radius: 0.25rem;
       -moz-border-radius-topright: 0.25rem;
            border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    -webkit-border-top-left-radius: 0;
       -moz-border-radius-topleft: 0;
            border-top-left-radius: 0;
    -webkit-border-top-right-radius: 0;
       -moz-border-radius-topright: 0;
            border-top-right-radius: 0; }

.nav-pills .nav-link {
  -webkit-border-radius: 0.25rem;
     -moz-border-radius: 0.25rem;
          border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
     -moz-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  -webkit-border-radius: 0.25rem;
     -moz-border-radius: 0.25rem;
          border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -moz-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
          -ms-flex-preferred-size: auto;
              flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -moz-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
          -ms-flex-preferred-size: auto;
              flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -moz-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
          -ms-flex-preferred-size: auto;
              flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -moz-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
          -ms-flex-preferred-size: auto;
              flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.25rem;
     -moz-border-radius: 0.25rem;
          border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out, -moz-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
       -moz-transform: translate(0, -50px);
        -ms-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
       -moz-transform: none;
        -ms-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
       -moz-transform: scale(1.02);
        -ms-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  max-height: -webkit-calc(100% - 1rem);
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: -webkit-calc(100vh - 1rem);
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: -webkit-calc(100% - 1rem);
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: -webkit-calc(100vh - 1rem);
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0.3rem;
     -moz-border-radius: 0.3rem;
          border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  -webkit-border-top-left-radius: -webkit-calc(0.3rem - 1px);
     -moz-border-radius-topleft: calc(0.3rem - 1px);
          border-top-left-radius: calc(0.3rem - 1px);
  -webkit-border-top-right-radius: -webkit-calc(0.3rem - 1px);
     -moz-border-radius-topright: calc(0.3rem - 1px);
          border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
     -moz-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  -webkit-border-bottom-right-radius: -webkit-calc(0.3rem - 1px);
     -moz-border-radius-bottomright: calc(0.3rem - 1px);
          border-bottom-right-radius: calc(0.3rem - 1px);
  -webkit-border-bottom-left-radius: -webkit-calc(0.3rem - 1px);
     -moz-border-radius-bottomleft: calc(0.3rem - 1px);
          border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: -webkit-calc(100% - 3.5rem);
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: -webkit-calc(100vh - 3.5rem);
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: -webkit-calc(100% - 3.5rem);
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: -webkit-calc(100vh - 3.5rem);
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

a {
  text-decoration: none; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0; }

html,
body {
  overflow-x: hidden;
  width: 100%;
  height: 100%; }

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "Rubik", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #242424; }

.d-n {
  display: none !important; }

.o-h {
  overflow: hidden !important; }

.btn {
  text-align: center;
  white-space: nowrap;
  display: block;
  -webkit-border-radius: 20px;
     -moz-border-radius: 20px;
          border-radius: 20px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 27px;
  border: 1px solid transparent; }
  .btn-fill {
    -webkit-transition: ease-in 0.3s;
    transition: ease-in 0.3s; }
    .btn-fill-pink {
      color: #ffffff;
      background: #8f00ff; }
      .btn-fill-pink:hover {
        border: 1px solid #8f00ff;
        color: #8f00ff;
        background: none; }
    .btn-fill-light {
      color: #242424;
      background: #ffffff; }
      .btn-fill-light:hover {
        border: 1px solid #ffffff;
        color: #ffffff;
        background: none; }
  .btn-empty {
    -webkit-transition: ease-in 0.3s;
    transition: ease-in 0.3s; }
    .btn-empty-pink {
      color: #8f00ff;
      border: 1px solid #8f00ff; }
      .btn-empty-pink:hover {
        border: 1px solid #8f00ff;
        color: #ffffff;
        background: #8f00ff; }
    .btn-empty-dark {
      color: #242424;
      border: 1px solid #242424; }
      .btn-empty-dark:hover {
        color: #8f00ff;
        border: 1px solid #8f00ff; }

.title {
  font-weight: bold;
  font-size: 48px;
  line-height: 57px;
  color: #242424; }

.title-small {
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  color: #000000; }

.description {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px; }

.no-wrap {
  white-space: nowrap; }

.search-page__input {
  border: none;
  outline: none;
  border: 1px solid #b2b2b2;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-border-radius: 49px;
     -moz-border-radius: 49px;
          border-radius: 49px;
  width: 239px;
  height: 41px;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  color: #242424;
  padding: 0 20px 0 40px;
  background-repeat: no-repeat;
  background-position: 5% center;
  -webkit-background-size: 20px 20px;
     -moz-background-size: 20px;
          background-size: 20px;
  -webkit-transition: ease-in 0.3s;
  transition: ease-in 0.3s; }
  .search-page__input::-webkit-input-placeholder {
    color: #d1d3d4; }
  .search-page__input::-moz-placeholder {
    color: #d1d3d4; }
  .search-page__input:-ms-input-placeholder {
    color: #d1d3d4; }
  .search-page__input::-ms-input-placeholder {
    color: #d1d3d4; }
  .search-page__input::placeholder {
    color: #d1d3d4; }
  .search-page__input-active {
    display: block;
    opacity: 1; }

.pages-wrapper {
  position: relative; }
  .pages-wrapper::before {
    content: "";
    position: absolute;
    width: 66.02px;
    height: 66.02px;
    left: -242px;
    top: 1000px;
    background: #f4f4f4;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    z-index: -1; }
  .pages-wrapper::after {
    content: "";
    position: absolute;
    width: 66.02px;
    height: 66.02px;
    right: -242px;
    top: 2700px;
    background: #f4f4f4;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    z-index: -1; }
  .pages-wrapper-bg-1 {
    position: absolute;
    width: 801px;
    height: 1798px;
    right: 0;
    top: 1000px;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1; }
  .pages-wrapper-bg-2 {
    position: absolute;
    width: 801px;
    height: 1798px;
    left: 0;
    top: 3000px;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1; }
  .pages-wrapper__card {
    width: 100%;
    min-height: 298px;
    padding: 36px 39px 38px 72px;
    margin-bottom: 30px;
    background: #ffffff;
    -webkit-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 24px;
       -moz-border-radius: 24px;
            border-radius: 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .pages-wrapper__card-info {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex; }
      .pages-wrapper__card-info-image {
        min-width: 119px;
        height: 125px;
        -webkit-background-size: contain;
           -moz-background-size: contain;
                background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 64px; }
      .pages-wrapper__card-info-text {
        color: #242424;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
        .pages-wrapper__card-info-text-title {
          font-weight: 500;
          font-size: 36px;
          line-height: 43px;
          margin-bottom: 32px; }
        .pages-wrapper__card-info-text-description {
          font-weight: 300;
          font-size: 17px;
          line-height: 20px; }
    .pages-wrapper__card-buttons {
      -webkit-align-self: flex-end;
          -ms-flex-item-align: end;
              align-self: flex-end;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex; }
      .pages-wrapper__card-buttons-l {
        margin-right: 24px; }

.header {
  -webkit-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
     -moz-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
  font-weight: 300;
  font-size: 17px;
  line-height: 20px; }
  .header-top {
    padding: 16px 0;
    background-color: #f4f4f4; }
    .header-top__wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
    .header-top__info {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex; }
  .header__tell {
    margin-left: 36px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center; }
    .header__tell-icon {
      width: 20px;
      height: 20px;
      -webkit-background-size: contain;
         -moz-background-size: contain;
              background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
    .header__tell-link {
      white-space: nowrap;
      margin-left: 10px;
      color: #242424;
      -webkit-transition: ease-in 0.3s;
      transition: ease-in 0.3s; }
      .header__tell-link:hover {
        color: #8f00ff; }
  .header__search {
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-transition: ease-in 0.3s;
    transition: ease-in 0.3s; }
    .header__search:hover span {
      color: #8f00ff; }
    .header__search:hover svg path {
      stroke: #8f00ff; }
    .header__search svg path {
      -webkit-transition: ease-in 0.3s;
      transition: ease-in 0.3s; }
    .header__search span {
      -webkit-transition: ease-in 0.3s;
      transition: ease-in 0.3s; }
    .header__search-icon {
      width: 20px;
      height: 20px;
      -webkit-background-size: contain;
         -moz-background-size: contain;
              background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 10px; }
    .header__search-input {
      display: none;
      border: none;
      outline: none;
      position: absolute;
      top: -10px;
      right: 0;
      border: 1px solid #b2b2b2;
      -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
              box-sizing: border-box;
      -webkit-border-radius: 49px;
         -moz-border-radius: 49px;
              border-radius: 49px;
      width: 239px;
      height: 41px;
      font-weight: 300;
      font-size: 16px;
      line-height: 28px;
      color: #242424;
      padding: 0 40px 0 20px;
      background-repeat: no-repeat;
      background-position: 90% center;
      opacity: 0;
      -webkit-transition: ease-in 0.3s;
      transition: ease-in 0.3s; }
      .header__search-input::-webkit-input-placeholder {
        color: #d1d3d4; }
      .header__search-input::-moz-placeholder {
        color: #d1d3d4; }
      .header__search-input:-ms-input-placeholder {
        color: #d1d3d4; }
      .header__search-input::-ms-input-placeholder {
        color: #d1d3d4; }
      .header__search-input::placeholder {
        color: #d1d3d4; }
      .header__search-input-active {
        display: block;
        opacity: 1; }
  .header-botton {
    background-color: #ffffff;
    padding: 28px 0;
    -webkit-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15); }
    .header-botton__wrapper {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
  .header-logo {
    display: block;
    width: 100%;
    height: 67px;
    -webkit-background-size: contain;
       -moz-background-size: contain;
            background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
  .header__button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }

.navbar {
  padding: 0;
  width: 100%;
  height: 100%; }
  .navbar-menu {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
       -moz-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .navbar-menu__link {
      color: #242424;
      -webkit-transition: ease-in 0.3s;
      transition: ease-in 0.3s; }
      .navbar-menu__link:hover {
        color: #8f00ff; }
      .navbar-menu__link-active {
        position: relative; }
        .navbar-menu__link-active::before {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #8f00ff; }
    .navbar-menu__dropdown {
      left: -50%;
      -webkit-border-radius: 19px;
         -moz-border-radius: 19px;
              border-radius: 19px;
      padding: 46px 51px; }
      .navbar-menu__dropdown-link {
        -webkit-transition: ease-in 0.3s;
        transition: ease-in 0.3s; }
        .navbar-menu__dropdown-link:not(.navbar-menu__dropdown-link:last-child) {
          margin-bottom: 42px; }
        .navbar-menu__dropdown-link:focus, .navbar-menu__dropdown-link:hover {
          background: none;
          color: #8f00ff; }

.burger {
  width: 100%;
  height: 100%;
  display: none;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .burger-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 25px;
    height: 21px;
    cursor: pointer;
    -webkit-transition: ease-in 0.3s;
    transition: ease-in 0.3s;
    display: flex;
    text-align: center;
    justify-content: space-between;
    flex-direction: column; }
    .burger-wrapper:hover .burger-wrapper__line {
      border: 1px solid #8f00ff;
      background: none; }
    .burger-wrapper__line {
      width: 100%;
      height: 4px;
      background: #8f00ff;
      -webkit-border-radius: 4px;
         -moz-border-radius: 4px;
              border-radius: 4px;
      border: 1px solid transparent;
      -webkit-transition: ease-in 0.3s;
      transition: ease-in 0.3s; }

.burger-active .burger-wrapper {
  padding: 9px 0 9px 0;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  background: #8f00ff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .burger-active .burger-wrapper__line {
    background: #ffffff; }
    .burger-active .burger-wrapper__line:nth-child(1) {
      margin: 0 auto;
      width: 18px;
      height: 1px;
      background: #ffffff; }
    .burger-active .burger-wrapper__line:nth-child(2) {
      margin: 0 auto;
      width: 12px;
      height: 1px;
      background: #ffffff; }
    .burger-active .burger-wrapper__line:nth-child(3) {
      margin: 0 auto;
      width: 9px;
      height: 1px;
      background: #ffffff; }
  .burger-active .burger-wrapper:hover .burger-wrapper__line {
    border: 1px solid #ffffff !important; }

.menu-mobile {
  top: -100%;
  display: none; }
  .menu-mobile .header__botton {
    display: none; }
  .menu-mobile .header-top {
    display: none; }
  .menu-mobile .navbar {
    display: none; }

.appointment-title {
  padding-top: 65px;
  padding-bottom: 73px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  color: #000000; }

.appointment-calendar {
  width: 100%;
  height: 825.51px;
  -webkit-background-size: cover;
     -moz-background-size: cover;
          background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.appointment-slider {
  padding: 60px 20px 100px 20px; }
  .appointment-slider .swiper-pagination {
    visibility: hidden; }
  .appointment-slider-button {
    position: absolute;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 52px;
    height: 52px;
    z-index: 99;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer; }
    .appointment-slider-button-prev {
      left: 0; }
    .appointment-slider-button-next {
      right: 0; }

.appointment-slide {
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 104px;
  padding-bottom: 66px;
  width: 920px;
  margin: 0 auto;
  min-height: 517.5px;
  background: #ffffff;
  -webkit-box-shadow: 0px 3.59375px 17.9687px rgba(0, 0, 0, 0.15);
     -moz-box-shadow: 0px 3.59375px 17.9687px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 3.59375px 17.9687px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 20px;
     -moz-border-radius: 20px;
          border-radius: 20px; }
  .appointment-slide__title {
    width: 405px;
    margin-left: 53px;
    font-weight: bold;
    font-size: 42.4349px;
    line-height: 50px;
    color: #8f00ff; }
  .appointment-slide__date {
    -webkit-align-self: start;
        -ms-flex-item-align: start;
            align-self: start;
    display: inline-block;
    font-weight: 500;
    font-size: 33.6498px;
    line-height: 40px;
    color: #ffffff;
    padding: 27px 46px;
    -webkit-border-top-right-radius: 19px;
       -moz-border-radius-topright: 19px;
            border-top-right-radius: 19px;
    -webkit-border-bottom-right-radius: 19px;
       -moz-border-radius-bottomright: 19px;
            border-bottom-right-radius: 19px;
    background: -webkit-gradient(linear, left top, left bottom, from(#b454ff), to(#8f00ff));
    background: -webkit-linear-gradient(top, #b454ff 0%, #8f00ff 100%);
    background: -moz-linear-gradient(top, #b454ff 0%, #8f00ff 100%);
    background: linear-gradient(180deg, #b454ff 0%, #8f00ff 100%); }

.appointment__bg-figura {
  position: absolute;
  top: 0;
  right: -33px;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
     -moz-background-size: cover;
          background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position: right 100%;
  z-index: 2; }

.appointment__bg-user {
  position: absolute;
  -webkit-border-top-left-radius: 100%;
     -moz-border-radius-topleft: 100%;
          border-top-left-radius: 100%;
  -webkit-border-bottom-left-radius: 100%;
     -moz-border-radius-bottomleft: 100%;
          border-bottom-left-radius: 100%;
  -webkit-border-bottom-right-radius: 80%;
     -moz-border-radius-bottomright: 80%;
          border-bottom-right-radius: 80%;
  top: -20px;
  right: -28px;
  width: 420px;
  height: 420px;
  -webkit-background-size: contain;
     -moz-background-size: contain;
          background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2; }

.medcenter {
  position: relative;
  background-color: #b454ff;
  padding: 60px 0 90px 0; }
  .medcenter .comma-bg {
    top: -1187px;
    right: 591px;
    position: absolute;
    width: 800px;
    height: 1000px;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1; }
  .medcenter::after {
    content: "";
    top: 170px;
    right: 177px;
    position: absolute;
    width: 46px;
    height: 46px;
    background: #f4f4f4;
    opacity: 0.4;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%; }
  .medcenter::before {
    content: "";
    top: 491px;
    right: 109px;
    position: absolute;
    width: 40px;
    height: 40px;
    background: #f4f4f4;
    opacity: 0.4;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%; }
  .medcenter-info {
    color: #ffffff; }
    .medcenter-info::after {
      content: "";
      top: 393px;
      left: -225px;
      position: absolute;
      width: 56px;
      height: 56px;
      background: #f4f4f4;
      opacity: 0.4;
      -webkit-border-radius: 50%;
         -moz-border-radius: 50%;
              border-radius: 50%; }
    .medcenter-info::before {
      content: "";
      top: 163px;
      left: -53px;
      position: absolute;
      width: 26.95px;
      height: 26.95px;
      background: #f4f4f4;
      opacity: 0.4;
      -webkit-border-radius: 50%;
         -moz-border-radius: 50%;
              border-radius: 50%; }
    .medcenter-info__title {
      font-weight: bold;
      font-size: 48px;
      line-height: 57px;
      color: inherit;
      margin-bottom: 26px; }
    .medcenter-info__text {
      width: 475px;
      font-weight: 300;
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 29px; }
    .medcenter-info__subtext {
      width: 350px;
      font-weight: 300;
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 36px; }
    .medcenter-info__button {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex; }
  .medcenter-image {
    position: relative;
    -webkit-border-radius: 31px;
       -moz-border-radius: 31px;
            border-radius: 31px;
    width: 100%;
    height: 482px;
    z-index: 999;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    .medcenter-image::before {
      content: "";
      width: 100%;
      height: 482px;
      position: absolute;
      top: -20px;
      right: -20px;
      -webkit-border-radius: 31px;
         -moz-border-radius: 31px;
              border-radius: 31px;
      z-index: -999;
      opacity: 0.5;
      border: 1px solid #ffffff; }
    .medcenter-image-img {
      position: relative;
      -webkit-border-radius: 31px;
         -moz-border-radius: 31px;
              border-radius: 31px;
      width: 100%;
      height: 482px;
      z-index: 999;
      -webkit-background-size: cover;
         -moz-background-size: cover;
              background-size: cover;
      background-repeat: no-repeat;
      background-position: center; }

.direction {
  padding: 62px 0 0px 0; }
  .direction-title {
    margin-bottom: 47px; }
  .direction-button {
    margin-top: 37px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .direction-slider {
    padding: 30px 0;
    margin: 0 auto; }
    .direction-slider__wrapper {
      width: 284px;
      margin: 0 auto;
      height: 336px;
      background: #ffffff;
      -webkit-box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
         -moz-box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
              box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
      -webkit-border-radius: 18px;
         -moz-border-radius: 18px;
              border-radius: 18px;
      background-position: top;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
         -moz-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      padding-bottom: 47px;
      position: relative;
      overflow: hidden;
      padding-left: 10px;
      padding-right: 10px; }
      .direction-slider__wrapper svg {
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-background-size: cover;
           -moz-background-size: cover;
                background-size: cover;
        background-repeat: no-repeat;
        background-position: center; }
      .direction-slider__wrapper-image {
        top: 0;
        left: 0;
        position: absolute;
        -webkit-background-size: cover;
           -moz-background-size: cover;
                background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 211px; }
      .direction-slider__wrapper-name {
        margin: 0 auto;
        width: 225px;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #242424;
        text-align: center; }
    .direction-slider-button {
      position: absolute;
      -webkit-background-size: cover;
         -moz-background-size: cover;
              background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 52px;
      height: 52px;
      z-index: 99;
      top: 50%;
      -webkit-transform: translateY(-50%);
         -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      cursor: pointer; }
      .direction-slider-button-prev {
        left: 0; }
      .direction-slider-button-next {
        right: 0; }

.review {
  padding-top: 102px;
  padding-bottom: 88px; }
  .review-wrapper {
    margin-bottom: 63px; }
    .review-wrapper__image {
      width: 212px;
      height: 212px;
      -webkit-background-size: cover;
         -moz-background-size: cover;
              background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      -webkit-border-radius: 50%;
         -moz-border-radius: 50%;
              border-radius: 50%;
      border: 5px solid #8f00ff;
      margin-bottom: 10px; }
    .review-wrapper__user-name {
      font-weight: 500;
      font-size: 36px;
      line-height: 43px;
      color: #8f00ff; }
    .review-wrapper__user-review {
      font-weight: 300;
      font-size: 24px;
      line-height: 28px;
      color: #242424; }
  .review-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  .review-title {
    width: 581px;
    margin-bottom: 59px; }

.specialists {
  padding: 81px 0 101px 0;
  -webkit-background-size: cover;
     -moz-background-size: cover;
          background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .specialists-title {
    color: #ffffff;
    margin-bottom: 60px; }
  .specialists-wrapper-card {
    width: 100%;
    min-height: 226px;
    padding: 23px 25px;
    background-color: #ffffff;
    -webkit-box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
            box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 19px;
       -moz-border-radius: 19px;
            border-radius: 19px;
    margin-bottom: 33px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .specialists-wrapper-card__spec {
      color: #000000;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .specialists-wrapper-card__spec-image {
        width: 108px;
        height: 108px;
        -webkit-background-size: cover;
           -moz-background-size: cover;
                background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        -webkit-border-radius: 50%;
           -moz-border-radius: 50%;
                border-radius: 50%; }
      .specialists-wrapper-card__spec-info {
        padding-left: 34px; }
      .specialists-wrapper-card__spec-name {
        display: block;
        font-weight: 500;
        font-size: 27.4286px;
        line-height: 33px;
        margin-bottom: 12px; }
      .specialists-wrapper-card__spec-specialization {
        display: block;
        font-weight: 300;
        font-size: 17px;
        line-height: 20px; }
    .specialists-wrapper-card__buttons {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
         -moz-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; }
      .specialists-wrapper-card__buttons-left {
        margin-right: 24px; }
  .specialists-button {
    margin-top: 35px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }

.footer {
  background-color: #f4f4f4;
  padding: 40px 0 33px 0; }
  .footer-contacts__menu-item-tell {
    color: inherit; }
  .footer-contacts__menu-item:nth-child(4) {
    margin-bottom: 10px; }
  .footer-wrapper {
    color: #000000; }
    .footer-wrapper__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 36px; }
    .footer-wrapper__list {
      font-weight: 300;
      font-size: 17px;
      line-height: 20px; }
      .footer-wrapper__list-item {
        margin-bottom: 20px; }
        .footer-wrapper__list-item:last-child {
          margin-bottom: 40px; }
  .footer-map__image {
    width: 100%;
    height: 387px;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 25px; }
  .footer-map__social {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
    .footer-map__social-bg {
      margin-right: 24px;
      display: block;
      width: 31px;
      height: 31px;
      -webkit-background-size: contain;
         -moz-background-size: contain;
              background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      -webkit-background-size: 100% 100%;
         -moz-background-size: 100%;
              background-size: 100%; }

.breadcrumbs {
  margin-top: 44px; }
  .breadcrumbs-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .breadcrumbs-wrapper__breadcrumb {
      font-weight: 300;
      font-size: 17px;
      line-height: 20px;
      color: #8f00ff; }
      .breadcrumbs-wrapper__breadcrumb-active {
        color: #000000;
        -webkit-transition: ease-in 0.3s;
        transition: ease-in 0.3s; }
        .breadcrumbs-wrapper__breadcrumb-active:hover {
          color: #8f00ff; }
    .breadcrumbs-wrapper__arrow {
      margin-left: 15px;
      margin-right: 15px; }

.directions {
  padding: 72px 0 102px 0; }
  .directions-title {
    margin-bottom: 30px; }
  .directions-description {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #242424;
    margin-bottom: 59px; }
  .directions__search-page {
    margin-bottom: 59px; }

.services {
  padding: 72px 0 116px 0; }
  .services-title {
    margin-bottom: 30px; }
  .services-description {
    margin-bottom: 59px; }
  .services__search-page {
    margin-bottom: 59px; }

.specialists-page {
  padding: 46px 0 105px 0; }
  .specialists-page-title {
    margin-bottom: 35px; }
  .specialists-page-description {
    margin-bottom: 35px; }
  .specialists-page__search-page {
    margin-bottom: 66px; }
  .specialists-page__wrapper {
    width: 100%;
    height: 1300px;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  .specialists-page__button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .specialists-page__button .btn {
      padding-left: 90px;
      padding-right: 90px; }

.modal-price__dialog {
  max-width: 950px;
  max-height: 503px; }

.modal-price__content {
  background: #ffffff;
  -webkit-border-radius: 39px;
     -moz-border-radius: 39px;
          border-radius: 39px;
  padding: 37px 38px;
  max-height: 703px;
  overflow: hidden;
  overflow-y: auto;
  min-height: 503px; }
  .modal-price__content::-webkit-scrollbar {
    width: 0; }

.modal-price__close {
  position: absolute;
  top: 0px;
  right: 0px;
  display: inline-block;
  cursor: pointer; }
  .modal-price__close:hover svg path {
    stroke: #8f00ff; }
  .modal-price__close svg path {
    -webkit-transition: ease-in 0.3s;
    transition: ease-in 0.3s; }

.reviews-page {
  padding: 57px 0 90px 0;
  position: relative; }
  .reviews-page::after {
    content: "";
    position: absolute;
    width: 66.02px;
    height: 66.02px;
    left: 95px;
    top: 180px;
    background: #f4f4f4;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    z-index: -1; }
  .reviews-page-bg {
    position: absolute;
    width: 1687.43px;
    height: 2072.37px;
    right: 0;
    top: 0;
    -webkit-background-size: contain;
       -moz-background-size: contain;
            background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1; }
  .reviews-page-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 81px; }
  .reviews-page-description {
    margin-bottom: 79px; }
  .reviews-page__button-modal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .reviews-page__button {
    margin-top: 42px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .reviews-page__card {
    background-color: #fff;
    -webkit-box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
            box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 36px;
       -moz-border-radius: 36px;
            border-radius: 36px;
    padding: 17px 47px 27px 45px;
    margin-bottom: 24px; }
    .reviews-page__card-user {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 42px; }
      .reviews-page__card-user-image {
        min-width: 135px;
        height: 135px;
        -webkit-background-size: cover;
           -moz-background-size: cover;
                background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        -webkit-border-radius: 50%;
           -moz-border-radius: 50%;
                border-radius: 50%;
        border: 5px solid #8f00ff; }
      .reviews-page__card-user-name {
        margin-left: 63px;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #000000; }
    .reviews-page__card-discription {
      font-weight: 300;
      font-size: 17px;
      line-height: 20px;
      color: #242424;
      margin-bottom: 55px; }
    .reviews-page__card-date {
      font-weight: 300;
      font-size: 17px;
      line-height: 20px;
      color: #242424;
      text-align: right; }

.reviews-modal__close {
  position: absolute;
  width: 30px;
  height: 30px;
  -webkit-background-size: cover;
     -moz-background-size: cover;
          background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  top: 20px;
  right: 20px; }

.reviews-modal input,
.reviews-modal textarea {
  outline: none; }

.reviews-modal-content {
  padding: 34px 51px 43px 47px;
  background: #ffffff;
  -webkit-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
     -moz-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 27px;
     -moz-border-radius: 27px;
          border-radius: 27px; }

.reviews-modal-header {
  margin: 0 auto;
  padding: 0;
  border-bottom: none;
  text-align: center;
  margin-bottom: 55px; }

.reviews-modal-title {
  margin: 0 auto;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  color: #000000; }

.reviews-modal-body {
  padding: 0; }

.reviews-modal-form {
  margin: 0 auto; }
  .reviews-modal-form__submit {
    margin-left: 25px; }
  .reviews-modal-form-input {
    display: block;
    margin: 0 auto;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 20px;
    padding-left: 25px;
    padding-right: 25px;
    border: none;
    width: 100%;
    height: 41px;
    margin-bottom: 28px;
    border: 1px solid #b2b2b2;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-border-radius: 49px;
       -moz-border-radius: 49px;
            border-radius: 49px; }
    .reviews-modal-form-input:not(:-moz-placeholder-shown) {
      color: #242424;
      border: 1px solid #000; }
    .reviews-modal-form-input:not(:-ms-input-placeholder) {
      color: #242424;
      border: 1px solid #000; }
    .reviews-modal-form-input:not(:placeholder-shown) {
      color: #242424;
      border: 1px solid #000; }
    .reviews-modal-form-input:focus {
      border: 1px solid #8f00ff;
      color: #8f00ff; }
      .reviews-modal-form-input:focus::-webkit-input-placeholder {
        color: #8f00ff; }
      .reviews-modal-form-input:focus::-moz-placeholder {
        color: #8f00ff; }
      .reviews-modal-form-input:focus:-ms-input-placeholder {
        color: #8f00ff; }
      .reviews-modal-form-input:focus::-ms-input-placeholder {
        color: #8f00ff; }
      .reviews-modal-form-input:focus::placeholder {
        color: #8f00ff; }
  .reviews-modal-form-textarea {
    display: block;
    margin: 0 auto;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 20px;
    padding: 25px;
    border: none;
    width: 100%;
    height: 193px;
    resize: none;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-border-radius: 19px;
       -moz-border-radius: 19px;
            border-radius: 19px;
    border: 1px solid #b2b2b2;
    margin-bottom: 55px; }
    .reviews-modal-form-textarea:invalid {
      border: 2px dashed #000; }
    .reviews-modal-form-textarea:not(:-moz-placeholder-shown) {
      color: #242424;
      border: 1px solid #000; }
    .reviews-modal-form-textarea:not(:-ms-input-placeholder) {
      color: #242424;
      border: 1px solid #000; }
    .reviews-modal-form-textarea:not(:placeholder-shown) {
      color: #242424;
      border: 1px solid #000; }
    .reviews-modal-form-textarea:focus {
      border: 1px solid #8f00ff;
      color: #8f00ff; }
      .reviews-modal-form-textarea:focus::-webkit-input-placeholder {
        color: #8f00ff; }
      .reviews-modal-form-textarea:focus::-moz-placeholder {
        color: #8f00ff; }
      .reviews-modal-form-textarea:focus:-ms-input-placeholder {
        color: #8f00ff; }
      .reviews-modal-form-textarea:focus::-ms-input-placeholder {
        color: #8f00ff; }
      .reviews-modal-form-textarea:focus::placeholder {
        color: #8f00ff; }

.reviews-modal-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto; }

.reviews-modal-footer {
  border-top: none; }

.licenses {
  padding: 61px 0 118px 0;
  position: relative; }
  .licenses::after {
    content: "";
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    z-index: -1;
    position: absolute;
    width: 66.02px;
    height: 66.02px;
    left: 208px;
    top: 433px;
    background: #f4f4f4; }
  .licenses-title {
    margin-bottom: 52px; }
  .licenses-description {
    margin-bottom: 74px; }
  .licenses-card {
    width: 100%;
    height: 437.72px;
    background: #ffffff;
    -webkit-box-shadow: 0px 4.38596px 21.9298px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 0px 4.38596px 21.9298px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 4.38596px 21.9298px rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 28px;
       -moz-border-radius: 28px;
            border-radius: 28px;
    padding: 24px 20px 24px 20px;
    margin: 0 auto;
    margin-bottom: 20px;
    -webkit-box-pack: content;
    -webkit-justify-content: content;
       -moz-box-pack: content;
        -ms-flex-pack: content;
            justify-content: content; }
    .licenses-card__images {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .licenses-card__images-big {
        display: block;
        position: relative;
        width: 207px;
        height: 305px; }
        .licenses-card__images-big img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
             object-fit: cover; }
        .licenses-card__images-big:hover .licenses-card__images-big-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -25%);
             -moz-transform: translate(-50%, -25%);
              -ms-transform: translate(-50%, -25%);
                  transform: translate(-50%, -25%);
          width: 32px;
          height: 32px;
          -webkit-background-size: cover;
             -moz-background-size: cover;
                  background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer; }
        .licenses-card__images-big:hover::before {
          content: "";
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          cursor: pointer;
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(143, 0, 255, 0.4);
          -webkit-backdrop-filter: blur(4px);
                  backdrop-filter: blur(4px);
          -webkit-border-radius: 10px;
             -moz-border-radius: 10px;
                  border-radius: 10px; }
      .licenses-card__images-small {
        position: relative; }
        .licenses-card__images-small-img {
          margin-bottom: 12px;
          -webkit-transition: ease-in 0.3s;
          transition: ease-in 0.3s;
          display: block;
          position: relative;
          width: 66.03px;
          height: 90.85px; }
          .licenses-card__images-small-img img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
               object-fit: cover; }
          .licenses-card__images-small-img:hover::before {
            content: "";
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(143, 0, 255, 0.4);
            -webkit-backdrop-filter: blur(4px);
                    backdrop-filter: blur(4px);
            -webkit-border-radius: 10px;
               -moz-border-radius: 10px;
                    border-radius: 10px;
            z-index: 1; }
          .licenses-card__images-small-img:hover .licenses-card__images-big-icon {
            position: absolute;
            width: 15px;
            height: 15px;
            -webkit-background-size: cover;
               -moz-background-size: cover;
                    background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 1;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -25%);
               -moz-transform: translate(-50%, -25%);
                -ms-transform: translate(-50%, -25%);
                    transform: translate(-50%, -25%); }
    .licenses-card__name {
      margin-top: 36px;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      text-align: center;
      color: #000000; }

.specialist-person__spec-specialization {
  display: block;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px; }
  .specialist-person__spec-specialization-name {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px; }

.specialist-person-do {
  padding: 67px 0 52px 0;
  background-color: #b454ff;
  position: relative; }
  .specialist-person-do::after {
    content: "";
    position: absolute;
    top: 21px;
    left: 5%;
    width: 56.36px;
    height: 56.36px;
    background-color: #f4f4f4;
    opacity: 0.4;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%; }
  .specialist-person-do::before {
    content: "";
    position: absolute;
    top: 59px;
    right: 5%;
    width: 40px;
    height: 40px;
    background-color: #f4f4f4;
    opacity: 0.4;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%; }
  .specialist-person-do__title {
    color: #ffffff;
    margin-bottom: 53px; }
  .specialist-person-do__list {
    color: #ffffff;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px; }
    .specialist-person-do__list li {
      position: relative;
      padding-left: 55px;
      margin-bottom: 40px; }
      .specialist-person-do__list li::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 26px;
        height: 26px;
        background-color: #ffffff;
        -webkit-border-radius: 50%;
           -moz-border-radius: 50%;
                border-radius: 50%; }

.make-appointment {
  padding: 71px 0 76px 0; }
  .make-appointment__title {
    margin-bottom: 50px; }
  .make-appointment-bg {
    width: 100%;
    height: 321px;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 58px; }
  .make-appointment__button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }

.description-something {
  margin-top: 66px; }
  .description-something__image {
    width: 350px;
    height: 350px;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    -webkit-filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1)); }
  .description-something__info {
    margin-left: 100px; }
    .description-something__info-title {
      margin-bottom: 28px; }
    .description-something__info-discription {
      display: block;
      font-weight: 300;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 38px; }
  .description-something__discription {
    font-weight: 300;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
    margin-top: 87px;
    margin-bottom: 61px; }

.equ-tech {
  padding-top: 67px;
  padding-bottom: 38px;
  background-color: #b454ff;
  position: relative; }
  .equ-tech::before {
    content: "";
    position: absolute;
    width: 46px;
    height: 46px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    top: 121px;
    right: 5%;
    background: #f4f4f4;
    opacity: 0.4; }
  .equ-tech .swiper-container {
    width: 100%;
    min-height: 360px;
    padding-bottom: 100px; }
  .equ-tech .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: #b2b2b2; }
    .equ-tech .swiper-pagination-bullet-active {
      width: 12px;
      height: 12px;
      background-color: #fff; }
  .equ-tech-info__title {
    color: #ffffff; }
  .equ-tech-info__discription {
    margin: 0;
    margin-top: 60px;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff; }
  .equ-tech-image {
    height: 343px;
    -webkit-background-size: contain;
       -moz-background-size: contain;
            background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-position: right top; }
  .equ-tech-button {
    position: absolute;
    width: 34px;
    height: 34px;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 20;
    cursor: pointer; }
    .equ-tech-button-prev {
      margin-left: -70px; }
    .equ-tech-button-next {
      margin-left: 70px; }

.price {
  position: relative;
  padding-top: 61px;
  padding-bottom: 105px; }
  .price::after {
    content: "";
    position: absolute;
    width: 66.02px;
    height: 66.02px;
    left: 208px;
    top: 233px;
    z-index: -1;
    background: #f4f4f4;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%; }
  .price-bg {
    overflow: hidden;
    position: absolute;
    top: 0px;
    right: -230px;
    width: 1023.84px;
    height: 1032.66px;
    z-index: -999;
    -webkit-background-size: contain;
       -moz-background-size: contain;
            background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
  .price-title {
    margin-bottom: 68px; }
  .price-description {
    margin-bottom: 46px; }
  .price-download {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
    .price-download__button {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex; }
    .price-download__icon {
      display: block;
      margin-left: 21px;
      width: 39px;
      height: 39px;
      background: #ffffff;
      -webkit-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
         -moz-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
              box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
      -webkit-border-radius: 50%;
         -moz-border-radius: 50%;
              border-radius: 50%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-transition: ease-in 0.5s;
      transition: ease-in 0.5s; }
      .price-download__icon:hover {
        background: #8f00ff; }
        .price-download__icon:hover svg path {
          fill: #ffffff; }
  .price-list {
    margin-top: 68px; }
    .price-list__title {
      margin-bottom: 35px; }
    .price-list__table {
      overflow-x: auto;
      border-spacing: 0; }
      .price-list__table-title-item {
        color: #ffffff;
        background: #8f00ff;
        border: 0.5px solid #f4f4f4;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px; }
      .price-list__table-line {
        font-weight: 300;
        font-size: 17px;
        line-height: 20px; }
      .price-list__table-cell {
        text-align: center;
        padding: 23px 0px;
        width: 222px;
        border: 0.5px solid #b2b2b2;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box; }
      .price-list__table-block-item {
        background: #ffffff; }

.about {
  padding: 65px 0;
  position: relative; }
  .about::after {
    content: "";
    position: absolute;
    width: 66.02px;
    height: 66.02px;
    right: 261px;
    top: 328px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    z-index: -1;
    background: #f4f4f4;
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
       -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
        -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
            transform: matrix(-1, 0, 0, 1, 0, 0); }
  .about-us-bg {
    position: absolute;
    width: 1023.84px;
    height: 1232.66px;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    top: 0;
    z-index: -1; }
  .about-image-discription {
    margin-left: auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    width: 445px;
    height: 343px;
    -webkit-background-size: cover;
       -moz-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-border-radius: 31px;
       -moz-border-radius: 31px;
            border-radius: 31px; }
  .about-description {
    margin-bottom: 75px; }
  .about-title {
    margin-bottom: 58px; }
  .about-swiper {
    width: 100%;
    height: 499px;
    -webkit-border-radius: 31px;
       -moz-border-radius: 31px;
            border-radius: 31px;
    margin-bottom: 58px; }
    .about-swiper__slide-image {
      width: 100%;
      height: 100%;
      -webkit-background-size: cover;
         -moz-background-size: cover;
              background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      -webkit-border-radius: 31px;
         -moz-border-radius: 31px;
              border-radius: 31px; }
    .about-swiper-button {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
         -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      width: 11px;
      height: 22px;
      -webkit-background-size: cover;
         -moz-background-size: cover;
              background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 5; }
      .about-swiper-button-prev {
        left: 23px; }
      .about-swiper-button-next {
        right: 23px; }

@media screen and (max-width: 1200px) {
  .header-logo {
    height: 55px; }
  .direction-slide {
    height: 317.5px; }
  .direction-slider__wrapper {
    background: #ffffff;
    -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15); }
  .appointment-slide {
    -webkit-box-shadow: 0px 3.59375px 5.9687px rgba(0, 0, 0, 0.15);
       -moz-box-shadow: 0px 3.59375px 5.9687px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 3.59375px 5.9687px rgba(0, 0, 0, 0.15);
    width: 100%; }
  .appointment__bg-user {
    width: 400px;
    height: 400px; } }

@media screen and (max-width: 991px) {
  body {
    padding-top: 100px; }
  .navbar {
    display: none; }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1001; }
    .header__botton {
      display: none; }
    .header__button {
      display: none; }
    .header-top {
      display: none; }
  .burger {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .medcenter {
    padding: 61px 0 83px 0; }
    .medcenter-info {
      margin-top: 20px;
      width: 100%; }
      .medcenter-info__title {
        font-size: 28px;
        line-height: 37px; }
      .medcenter-info__text {
        width: 100%; }
      .medcenter-info__subtext {
        width: 100%; }
    .medcenter-image {
      height: 382px; }
      .medcenter-image::before {
        height: 382px; }
      .medcenter-image-img {
        height: 382px; }
  .menu-mobile {
    position: fixed;
    display: block;
    z-index: 1000;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    -webkit-transition: ease-in 0.3s;
    transition: ease-in 0.3s; }
    .menu-mobile-active {
      left: 0;
      right: 0;
      bottom: 0;
      background: #8f00ff;
      top: 100px; }
    .menu-mobile .header-botton__wrapper {
      margin-left: 10px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
         -moz-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
    .menu-mobile .navbar {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-top: 40px;
      width: auto;
      height: auto;
      padding: 0; }
      .menu-mobile .navbar-menu {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
        .menu-mobile .navbar-menu__item {
          margin-bottom: 30px; }
        .menu-mobile .navbar-menu__dropdown {
          left: -60%; }
        .menu-mobile .navbar-menu__link {
          font-weight: 500;
          font-size: 24px;
          line-height: 20px;
          color: #ffffff;
          -webkit-transition: ease-in 0.3s;
          transition: ease-in 0.3s; }
          .menu-mobile .navbar-menu__link:hover {
            color: #242424; }
          .menu-mobile .navbar-menu__link-active::before {
            background: #000; }
    .menu-mobile .header-top {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      background: none; }
      .menu-mobile .header-top__wrapper {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
      .menu-mobile .header-top__info {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
    .menu-mobile .header__address {
      font-weight: 300;
      font-size: 17px;
      line-height: 20px;
      color: #ffffff;
      margin-bottom: 20px; }
    .menu-mobile .header__tell {
      margin-left: 36px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      text-align: center; }
      .menu-mobile .header__tell-icon {
        width: 20px;
        height: 20px;
        -webkit-background-size: contain;
           -moz-background-size: contain;
                background-size: contain;
        background-repeat: no-repeat;
        background-position: center; }
      .menu-mobile .header__tell-link {
        white-space: nowrap;
        margin-left: 10px;
        font-weight: 300;
        font-size: 17px;
        line-height: 20px;
        color: #ffffff;
        -webkit-transition: ease-in 0.3s;
        transition: ease-in 0.3s;
        margin-bottom: 20px; }
        .menu-mobile .header__tell-link:hover {
          color: #242424; }
    .menu-mobile .header__search:hover span {
      color: #242424; }
    .menu-mobile .header__search:hover svg path {
      stroke: #242424; }
    .menu-mobile .header__search span {
      font-weight: 300;
      font-size: 17px;
      line-height: 20px;
      color: #ffffff; }
    .menu-mobile .header__search-input {
      right: -100%; }
    .menu-mobile .header__button {
      margin-top: 50px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
         -moz-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
  .review {
    padding-top: 60px;
    padding-bottom: 40px; }
    .review-wrapper {
      margin-bottom: 63px; }
      .review-wrapper__image {
        margin: auto; }
      .review-wrapper__user-name {
        margin-top: 20px;
        font-size: 24px; }
      .review-wrapper__user-review {
        font-size: 18px; }
    .review-title {
      width: auto;
      margin-bottom: 39px;
      font-size: 36px; }
  .appointment-slider .swiper-pagination {
    visibility: visible; }
    .appointment-slider .swiper-pagination .swiper-pagination-bullet-active {
      background-color: #8f00ff; }
  .appointment-slide {
    min-height: 410px;
    width: 90%;
    padding-top: 64px;
    padding-bottom: 66px; }
    .appointment-slide__title {
      font-size: 28px;
      line-height: 28px;
      margin-left: 20px;
      width: 355px;
      z-index: 89; }
    .appointment-slide__date {
      -webkit-align-self: start;
          -ms-flex-item-align: start;
              align-self: start;
      display: inline-block;
      font-weight: 500;
      font-size: 33.6498px; }
  .appointment__bg-user {
    width: 290px;
    height: 290px; }
  .appointment-slider {
    padding: 60px -20px 100px -20px; }
    .appointment-slider-button {
      display: none; }
  .reviews-page-title {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .reviews-page-title .title {
      margin-bottom: 20px; }
  .description-something__image {
    margin: 0 auto;
    margin-bottom: 30px; }
  .description-something__info {
    margin-left: 0px; }
    .description-something__info-title {
      margin-bottom: 28px;
      text-align: center; }
  .equ-tech-image {
    background-position: center;
    margin-bottom: 15px; }
  .equ-tech-info__title {
    text-align: center; }
  .equ-tech-info__discription {
    margin-top: 30px; }
  .about-image-discription {
    width: 100%; }
  .licenses-card {
    width: 100%; }
    .licenses-card__images {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .licenses-card__images-small {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex; }
        .licenses-card__images-small-img {
          width: 130px;
          height: 205px;
          margin-left: 20px; }
  .menu-mobile .header-botton__wrapper {
    height: auto; }
  .menu-mobile .header__button {
    padding-bottom: 60px; }
  .menu-mobile .dropdown {
    position: unset; }
    .menu-mobile .dropdown .show {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
         -moz-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
      -webkit-justify-content: start;
         -moz-box-pack: start;
          -ms-flex-pack: start;
              justify-content: start;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-top: -100px; }
    .menu-mobile .dropdown-menu__close {
      position: absolute;
      top: 150px;
      right: 100px;
      display: inline-block;
      cursor: pointer; }
      .menu-mobile .dropdown-menu__close:hover svg path {
        stroke: #8f00ff; }
      .menu-mobile .dropdown-menu__close svg path {
        -webkit-transition: ease-in 0.3s;
        transition: ease-in 0.3s; }
    .menu-mobile .dropdown-item {
      display: inline-block;
      width: auto;
      padding: 0;
      font-size: 40px; }
  .menu-mobile .navbar-menu__dropdown {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    -webkit-border-radius: 0;
       -moz-border-radius: 0;
            border-radius: 0;
    overflow-y: auto; }
  .menu-mobile .navbar {
    position: unset; } }

@media screen and (max-width: 767px) {
  .header-logo {
    height: 60px; }
  .header-top__info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .header__tell {
    margin-top: 10px;
    margin-left: 0px;
    cursor: pointer; }
    .header__tell-icon svg path {
      fill: #ffffff; }
  .header__search {
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .pages-wrapper__card {
    padding: 36px 39px 38px 39px; }
    .pages-wrapper__card-info {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      margin-bottom: 30px; }
      .pages-wrapper__card-info-image {
        margin-bottom: 20px; }
      .pages-wrapper__card-info-text-title {
        font-size: 28px;
        margin-bottom: 15px; }
      .pages-wrapper__card-info-text-description {
        margin: 0; }
    .pages-wrapper__card-buttons {
      -webkit-align-self: flex-end;
          -ms-flex-item-align: end;
              align-self: flex-end;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .pages-wrapper__card-buttons .btn {
        white-space: inherit;
        width: 300px; }
      .pages-wrapper__card-buttons-l {
        margin-right: 0; }
      .pages-wrapper__card-buttons-r {
        margin-top: 10px; }
  .reviews-page-title {
    margin-bottom: 30px; }
  .reviews-page-description {
    font-size: 18px; }
  .title-small {
    font-size: 24px;
    line-height: 30px; }
  .specialist-person__spec-discription, .specialist-person__spec-specialization, .specialist-person__spec-specialization-name {
    font-size: 18px;
    margin-bottom: 15px; }
  .specialist-person-do__title {
    margin-bottom: 20px; }
  .specialist-person-do__list {
    font-size: 18px;
    line-height: 24px; }
    .specialist-person-do__list li {
      padding-left: 45px;
      margin-bottom: 20px; }
      .specialist-person-do__list li::after {
        width: 20px;
        height: 20px; }
  .description-something__image {
    width: 200px;
    height: 200px;
    margin-bottom: 20px; }
  .description-something__info-title {
    font-size: 28px;
    line-height: 1.6; }
  .description-something__info-discription {
    font-size: 18px;
    margin-bottom: 15px; }
  .description-something__discription {
    margin-top: 37px;
    font-size: 18px; }
  .make-appointment {
    padding: 41px 0 76px 0; }
    .make-appointment__title {
      margin-bottom: 50px; }
    .make-appointment-bg {
      width: 100%;
      height: 321px;
      -webkit-background-size: cover;
         -moz-background-size: cover;
              background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 58px; }
    .make-appointment__button {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex; }
  .equ-tech-info__discription {
    margin-top: 30px;
    font-size: 18px; }
  .title {
    font-size: 28px;
    line-height: 37px; }
  .description {
    font-size: 18px;
    line-height: 24px; }
  .price {
    padding-top: 51px;
    padding-bottom: 85px; }
    .price-title {
      margin-bottom: 30px; }
    .price-list {
      margin-top: 68px; }
      .price-list__title {
        margin-bottom: 35px; }
      .price-list__table-title-item {
        font-size: 13px; }
      .price-list__table-line {
        font-size: 13px;
        line-height: 20px; }
  .review-wrapper {
    margin-bottom: 30px; }
    .review-wrapper__image {
      margin: auto; }
    .review-wrapper__user-name {
      display: block;
      text-align: center; }
    .review-wrapper__user-review {
      font-size: 18px; }
  .review-title {
    margin-bottom: 39px;
    font-size: 36px; }
  .pages-wrapper__card-info-image {
    margin: auto;
    margin-bottom: 20px; }
  .pages-wrapper__card-info-text-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 43px;
    margin-bottom: 32px;
    text-align: center; }
  .pages-wrapper__card-info-text-description {
    font-size: 16px; }
  .appointment-slider {
    padding-bottom: 70px; }
  .appointment-slide {
    min-height: 320px; }
    .appointment-slide__title {
      font-size: 28px;
      line-height: 28px;
      margin-left: 20px;
      width: 355px;
      z-index: 89; }
    .appointment-slide__date {
      -webkit-align-self: start;
          -ms-flex-item-align: start;
              align-self: start;
      display: inline-block;
      font-weight: 500;
      font-size: 18.6498px;
      padding: 10px 20px; }
  .appointment__bg-user {
    width: 220px;
    height: 220px; }
  .appointment-slider {
    padding: 60px -20px 100px -20px; }
    .appointment-slider-button {
      display: none; }
  .licenses-card {
    width: 100%; }
    .licenses-card__images {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .licenses-card__images-big {
        width: 310px;
        height: 305px; }
      .licenses-card__images-small {
        justify-self: flex-end;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap; }
        .licenses-card__images-small-img {
          width: 95px;
          height: 145px;
          margin-left: 20px; }
  .menu-mobile {
    width: 100%;
    height: auto;
    overflow: auto; }
    .menu-mobile .dropdown .show {
      margin-top: 0px; }
    .menu-mobile .dropdown-item {
      font-size: 30px; } }

@media screen and (max-width: 576px) {
  .reviews-page__card {
    padding-top: 20px; }
    .reviews-page__card-user {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-bottom: 25px; }
      .reviews-page__card-user-name {
        width: 100%;
        text-align: center;
        margin: 0;
        margin-top: 10px; }
  .reviews-modal-header {
    margin-bottom: 20px; }
  .reviews-modal-buttons {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0; }
  .reviews-modal-form {
    margin: 0 auto; }
    .reviews-modal-form-input {
      margin-bottom: 10px; }
    .reviews-modal-form-textarea {
      margin-bottom: 20px; }
    .reviews-modal-form__exit btn {
      width: 100%; }
    .reviews-modal-form__submit {
      margin: 0;
      margin-top: 20px; }
      .reviews-modal-form__submit input {
        width: 100%; }
  .about {
    padding: 35px 0; }
    .about-title {
      margin-bottom: 28px; }
    .about-swiper {
      height: 300px; }
  .licenses-card {
    padding-left: 40px;
    width: 350px; }
    .licenses-card__images {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .licenses-card__images-big {
        width: 515px;
        height: 305px; }
      .licenses-card__images-small {
        justify-self: flex-end;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap; }
        .licenses-card__images-small-img {
          width: 66.03px;
          height: 90.85px;
          margin-left: 20px; }
  .menu-mobile {
    width: 100%;
    height: auto;
    overflow: auto;
    overflow-x: hidden;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box; }
    .menu-mobile .header-botton__wrapper {
      height: auto; }
    .menu-mobile .header__button {
      padding-bottom: 60px; } }

@media screen and (max-width: 400px) {
  .equ-tech {
    padding-top: 0px; }
  .title {
    font-size: 24px;
    line-height: 30px; }
  .title-small {
    font-size: 20px;
    line-height: 30px; }
  .specialists {
    padding: 31px 0 51px 0; }
    .specialists-title {
      margin-bottom: 64px; }
    .specialists-wrapper-card {
      -webkit-border-radius: 19px;
         -moz-border-radius: 19px;
              border-radius: 19px;
      margin-bottom: 33px; }
      .specialists-wrapper-card__spec {
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-bottom: 20px; }
        .specialists-wrapper-card__spec-info {
          padding-left: 20px; }
        .specialists-wrapper-card__spec-name {
          font-size: 24px;
          line-height: 24px;
          margin-bottom: 12px; }
        .specialists-wrapper-card__spec-specialization {
          font-size: 16px;
          line-height: 20px; }
      .specialists-wrapper-card__buttons {
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap; }
        .specialists-wrapper-card__buttons-left {
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px; }
        .specialists-wrapper-card__buttons-right {
          width: 100%; }
  .appointment-slider {
    padding-bottom: 70px; }
  .appointment-slide {
    min-height: 180px; }
    .appointment-slide__title {
      font-size: 20px;
      line-height: 28px;
      margin-left: 20px;
      width: 265px;
      z-index: 89; }
    .appointment-slide__date {
      margin-top: 30px;
      -webkit-align-self: start;
          -ms-flex-item-align: start;
              align-self: start;
      display: inline-block;
      font-weight: 500;
      font-size: 18.6498px;
      padding: 10px 20px;
      z-index: 2; }
  .appointment__bg-user {
    width: 200px;
    height: 200px; }
  .appointment-slider {
    padding: 60px -20px 100px -20px; }
    .appointment-slider-button {
      display: none; }
  .pages-wrapper__card-buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
    .pages-wrapper__card-buttons .btn {
      width: 100%; }
    .pages-wrapper__card-buttons-l {
      width: 100%; }
  .menu-mobile .dropdown-menu__close {
    top: 50px;
    right: 50px; }
  .price-download__icon {
    margin-left: 5px; }
  .licenses-card {
    padding-left: 40px;
    width: 100%;
    height: 680px; }
    .licenses-card__name {
      margin-top: 0;
      font-size: 14px; }
    .licenses-card__images {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .licenses-card__images-big {
        width: 515px;
        height: 355px;
        margin-bottom: 20px; }
      .licenses-card__images-small {
        justify-self: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
        .licenses-card__images-small-img {
          width: 66.03px;
          height: 90.85px;
          margin-left: 20px; } }
